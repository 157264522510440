<template>
  <div id="outsidersCleanRoomRegister">
    <el-dialog
      :title="outsidersCleanRoomRegisterFormTitle"
      width="880px"
      :visible.sync="outsidersCleanRoomRegisterDialogVisible"
      :close-on-click-modal="false"
      @close="outsidersCleanRoomRegisterDialogClose"
    >
      <el-form
        ref="outsidersCleanRoomRegisterFormRef"
        :model="outsidersCleanRoomRegisterForm"
        :rules="outsidersCleanRoomRegisterFormRules"
        label-position="right"
        label-width="180px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="登记日期" prop="registrationDate">
              <el-date-picker
                v-model="outsidersCleanRoomRegisterForm.registrationDate"
                placeholder="请选择登记日期"
                value-format="yyyy-MM-dd"
                :disabled="outsidersCleanRoomRegisterFormTitle !== '新增外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '修改外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '外来人员进出洁净室(区)登记表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="进区时间" prop="entryTime">
              <el-time-picker
                v-model="outsidersCleanRoomRegisterForm.entryTime"
                placeholder="请输入进区时间"
                value-format="HH:mm"
                :disabled="outsidersCleanRoomRegisterFormTitle !== '新增外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '修改外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '外来人员进出洁净室(区)登记表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出区时间" prop="outTime">
              <el-time-picker
                v-model="outsidersCleanRoomRegisterForm.outTime"
                placeholder="请输入出区时间"
                value-format="HH:mm"
                :disabled="outsidersCleanRoomRegisterFormTitle !== '新增外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '修改外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '外来人员进出洁净室(区)登记表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="进区名称" prop="areaName">
              <el-input
                v-model="outsidersCleanRoomRegisterForm.areaName"
                placeholder="请输入进区名称"
                clearable
                :disabled="outsidersCleanRoomRegisterFormTitle !== '新增外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '修改外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '外来人员进出洁净室(区)登记表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="等级" prop="grade">
              <el-input
                v-model="outsidersCleanRoomRegisterForm.grade"
                placeholder="请输入等级"
                clearable
                :disabled="outsidersCleanRoomRegisterFormTitle !== '新增外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '修改外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '外来人员进出洁净室(区)登记表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="进区人员名字登记" prop="personName">
              <el-input
                v-model="outsidersCleanRoomRegisterForm.personName"
                placeholder="请输入进区人员名字登记"
                clearable
                :disabled="outsidersCleanRoomRegisterFormTitle !== '新增外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '修改外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '外来人员进出洁净室(区)登记表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="事由" prop="cause">
              <el-input
                v-model="outsidersCleanRoomRegisterForm.cause"
                placeholder="请输入事由"
                clearable
                :disabled="outsidersCleanRoomRegisterFormTitle !== '新增外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '修改外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '外来人员进出洁净室(区)登记表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="洁净服/鞋使用登记情况" prop="clothesShoesStatus">
              <el-input
                v-model="outsidersCleanRoomRegisterForm.clothesShoesStatus"
                placeholder="请输入洁净服/鞋使用登记情况"
                clearable
                :disabled="outsidersCleanRoomRegisterFormTitle !== '新增外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '修改外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '外来人员进出洁净室(区)登记表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="outsidersCleanRoomRegisterForm.reviewResult"
                :disabled="outsidersCleanRoomRegisterFormTitle !== '复核外来人员进出洁净室(区)登记表'
                  && outsidersCleanRoomRegisterFormTitle !== '外来人员进出洁净室(区)登记表详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="outsidersCleanRoomRegisterDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="outsidersCleanRoomRegisterFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="登记日期">
        <el-date-picker v-model="searchForm.registrationDate" placeholder="请选择登记日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['OUTSIDERS_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="outsidersCleanRoomRegisterPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column label="登记日期">
        <template slot-scope="scope">
          <span v-if="scope.row.registrationDate">{{ scope.row.registrationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="entryTime" label="进区时间" />
      <el-table-column prop="outTime" label="出区时间" />
      <el-table-column prop="areaName" label="进区名称" />
      <el-table-column prop="grade" label="等级" />
      <el-table-column prop="personName" label="进区人员名字登记" />
      <el-table-column prop="cause" label="事由" />
      <el-table-column prop="clothesShoesStatus" label="洁净服/鞋使用登记情况" />
      <el-table-column prop="recorder" label="记录人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.recordDate">{{ scope.row.recordDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['OUTSIDERS_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['OUTSIDERS_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['OUTSIDERS_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="outsidersCleanRoomRegisterPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addOutsidersCleanRoomRegister,
  deleteOutsidersCleanRoomRegister,
  updateOutsidersCleanRoomRegister,
  selectOutsidersCleanRoomRegisterInfo,
  selectOutsidersCleanRoomRegisterList,
  reviewOutsidersCleanRoomRegister
} from '@/api/produce/outsidersCleanRoomRegister'

export default {
  data () {
    return {
      outsidersCleanRoomRegisterDialogVisible: false,
      outsidersCleanRoomRegisterFormTitle: '',
      outsidersCleanRoomRegisterForm: {
        id: '',
        registrationDate: '',
        entryTime: '',
        outTime: '',
        areaName: '',
        grade: '',
        personName: '',
        cause: '',
        clothesShoesStatus: '',
        status: '',
        reviewResult: '',
        taskId: ''
      },
      outsidersCleanRoomRegisterFormRules: {
        registrationDate: [{ required: true, message: '登记日期不能为空', trigger: ['blur', 'change']}]
      },
      outsidersCleanRoomRegisterPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        registrationDate: ''
      }
    }
  },
  created () {
    selectOutsidersCleanRoomRegisterList(this.searchForm).then(res => {
      this.outsidersCleanRoomRegisterPage = res
    })
  },
  methods: {
    outsidersCleanRoomRegisterDialogClose () {
      this.$refs.outsidersCleanRoomRegisterFormRef.resetFields()
    },
    outsidersCleanRoomRegisterFormSubmit () {
      if (this.outsidersCleanRoomRegisterFormTitle === '外来人员进出洁净室(区)登记表详情') {
        this.outsidersCleanRoomRegisterDialogVisible = false
        return
      }
      this.$refs.outsidersCleanRoomRegisterFormRef.validate(async valid => {
        if (valid) {
          if (this.outsidersCleanRoomRegisterFormTitle === '新增外来人员进出洁净室(区)登记表') {
            this.outsidersCleanRoomRegisterForm.id = ''
            this.outsidersCleanRoomRegisterForm.status = 1
            await addOutsidersCleanRoomRegister(this.outsidersCleanRoomRegisterForm)
          } else if (this.outsidersCleanRoomRegisterFormTitle === '修改外来人员进出洁净室(区)登记表') {
            await updateOutsidersCleanRoomRegister(this.outsidersCleanRoomRegisterForm)
          } else if (this.outsidersCleanRoomRegisterFormTitle === '复核外来人员进出洁净室(区)登记表') {
            this.outsidersCleanRoomRegisterForm.status = 2
            await reviewOutsidersCleanRoomRegister(this.outsidersCleanRoomRegisterForm)
          }
          this.outsidersCleanRoomRegisterPage = await selectOutsidersCleanRoomRegisterList(this.searchForm)
          this.outsidersCleanRoomRegisterDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.outsidersCleanRoomRegisterFormTitle = '新增外来人员进出洁净室(区)登记表'
      this.outsidersCleanRoomRegisterDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteOutsidersCleanRoomRegister(row.id)
        if (this.outsidersCleanRoomRegisterPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.outsidersCleanRoomRegisterPage = await selectOutsidersCleanRoomRegisterList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.outsidersCleanRoomRegisterFormTitle = '修改外来人员进出洁净室(区)登记表'
      this.outsidersCleanRoomRegisterDialogVisible = true
      this.selectOutsidersCleanRoomRegisterInfoById(row.id)
    },
    handleReview (index, row) {
      this.outsidersCleanRoomRegisterFormTitle = '复核外来人员进出洁净室(区)登记表'
      this.outsidersCleanRoomRegisterDialogVisible = true
      this.selectOutsidersCleanRoomRegisterInfoById(row.id)
    },
    handleInfo (index, row) {
      this.outsidersCleanRoomRegisterFormTitle = '外来人员进出洁净室(区)登记表详情'
      this.outsidersCleanRoomRegisterDialogVisible = true
      this.selectOutsidersCleanRoomRegisterInfoById(row.id)
    },
    selectOutsidersCleanRoomRegisterInfoById (id) {
      selectOutsidersCleanRoomRegisterInfo(id).then(res => {
        this.outsidersCleanRoomRegisterForm.id = res.id
        this.outsidersCleanRoomRegisterForm.registrationDate = res.registrationDate
        this.outsidersCleanRoomRegisterForm.entryTime = res.entryTime
        this.outsidersCleanRoomRegisterForm.outTime = res.outTime
        this.outsidersCleanRoomRegisterForm.areaName = res.areaName
        this.outsidersCleanRoomRegisterForm.grade = res.grade
        this.outsidersCleanRoomRegisterForm.personName = res.personName
        this.outsidersCleanRoomRegisterForm.cause = res.cause
        this.outsidersCleanRoomRegisterForm.clothesShoesStatus = res.clothesShoesStatus
        this.outsidersCleanRoomRegisterForm.status = res.status
        this.outsidersCleanRoomRegisterForm.reviewResult = res.reviewResult
        this.outsidersCleanRoomRegisterForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectOutsidersCleanRoomRegisterList(this.searchForm).then(res => {
        this.outsidersCleanRoomRegisterPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectOutsidersCleanRoomRegisterList(this.searchForm).then(res => {
        this.outsidersCleanRoomRegisterPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectOutsidersCleanRoomRegisterList(this.searchForm).then(res => {
        this.outsidersCleanRoomRegisterPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['OUTSIDERS_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
