import axios from '@/common/axios'
import qs from 'qs'

export function addOutsidersCleanRoomRegister (data) {
  return axios({
    method: 'post',
    url: '/production/outsidersCleanRoomRegister/add',
    data: qs.stringify(data)
  })
}

export function deleteOutsidersCleanRoomRegister (id) {
  return axios({
    method: 'delete',
    url: '/production/outsidersCleanRoomRegister/delete/' + id
  })
}

export function updateOutsidersCleanRoomRegister (data) {
  return axios({
    method: 'put',
    url: '/production/outsidersCleanRoomRegister/update',
    data: qs.stringify(data)
  })
}

export function selectOutsidersCleanRoomRegisterInfo (id) {
  return axios({
    method: 'get',
    url: '/production/outsidersCleanRoomRegister/info/' + id
  })
}

export function selectOutsidersCleanRoomRegisterList (query) {
  return axios({
    method: 'get',
    url: '/production/outsidersCleanRoomRegister/list',
    params: query
  })
}

export function reviewOutsidersCleanRoomRegister (data) {
  return axios({
    method: 'put',
    url: '/production/outsidersCleanRoomRegister/updateReview',
    data: qs.stringify(data)
  })
}
